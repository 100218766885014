<template>
  <main-template>
    <secondary-pages-background />
    <div class="p-product p-other">
      <div class="container">
        <div class="product">
          <div class="wrapper-img" v-if="product && product.imageUrl">
          <img :src="require(`@/assets/img/products/${product.imageUrl}`)" alt="Product Image" />
        </div>
        <div v-else>
          <p>Loading product...</p> <!-- Optional loading state -->
        </div>
          <div class="content">
            <!-- Display dynamic product title -->
            <h2 class="title fs--medium fw--bold mb-xs">
              {{ product.title }}
            </h2>
            <div class="editor mb-l">
              <!-- Display dynamic product description -->
              <p v-for="paragraph in product.description" :key="paragraph">
                {{ paragraph }}
              </p>
              <ul class="mb-xs">
                <li v-for="(feature, index) in product.features" :key="index">
                  {{ feature }}
                </li>
              </ul>
            </div>
            <app-button
            class="btn-to-cart mb-l"
            title="Приобрести"
            description="Добавить в корзину"
            @click="redirectToProduct">
              <icon-debug class="icon" />
            </app-button>
            <!--
            <div class="wrapper-links">
              <app-link to="/renewal#hero">
                Продлить
              </app-link>
              <app-link to="/activation#hero">
                Активировать
              </app-link>
            </div>
          -->
          </div>
        </div>
      </div>
    </div>
  </main-template>
</template>

<script>
import MainTemplate from '@/layouts/MainTemplate'
import AppButton from '@/components/common/AppButton'
//import AppLink from '@/components/common/AppLink'
import SecondaryPagesBackground from '@/components/SecondaryPagesBackground'
import IconDebug from '@/assets/img/icons/debug.svg'
import axios from 'axios'
import { products } from '@/Products';

export default {
  name: 'Product',
  components: {
    MainTemplate,
    AppButton,
    SecondaryPagesBackground,
    //AppLink,
    IconDebug
  },
  data() {
    return {
      product: null
    }
  },
  mounted() {
    const productId = parseInt(this.$route.params.id);  // Get product ID from URL
    this.product = products.find(p => p.id === productId);
  },
  methods: {
    async fetchProductData() {
      const productId = this.$route.params.id
      try {
        const response = await axios.get(`/api/products/${productId}`)
        this.product = response.data
      } catch (error) {
        console.error('Error fetching product data:', error)
      }
    },
    redirectToProduct() {
      const link = this.product.link;
      window.location.href = link;
    }
  }
}
</script>

<style lang="scss" scoped>
  .p-product {
    .container {
      padding-top: 230px;
      @include max-w-lg {
        padding-top: 160px;
      }
      @include max-w-xs {
        padding-top: 100px;
      }
    }
    .product {
      position: relative;
      padding-top: 66px;
      padding-left: 36px;
      max-width: 864px;
      @include max-w-sm {
        padding-top: 50px;
        padding-left: 0;
      }
      .wrapper-img {
        z-index: 1;
        position: absolute;
        left: -100px;
        top: -80px;
        width: 440px;
        height: 314px;
        @include max-w-sm {
          width: 270px;
          height: 130px;
          top: -30px;
          left: calc(50% - 170px);
        }
        img {
          object-fit: contain;
          width: 70%;
          height: 70%;
          @include max-w-sm {
            width: 120%;
            height: 120%;
        }
        }
      }
      .content {
        padding: 16px;
        background: linear-gradient(180deg, rgba(250, 250, 250, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%);
        backdrop-filter: blur(12px);
        border-radius: 16px;
        .title {
          margin-top: 80px;
        }
        .btn-to-cart {
          .icon {
            path {
              stroke: $dark;
              fill: $dark;
            }
          }
        }
        .wrapper-links {
          display: flex;
          @include max-w-xs {
            flex-direction: column;
          }
          .app-link {
            width: calc(50% - 10px);
            @include max-w-xs {
              width: 100%;
              margin-bottom: 12px;
            }
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
</style>
